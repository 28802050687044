<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="{'modal-container-mobile': isMobile}">
          <div class="modal-header">
            <h5 class="modal-title" id="quote_modal_title">Reset password</h5>
            <button type="button" class="close" @click="$emit('close')"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body" v-if="ready">
            <div class="col-12" v-if="!sent">
              <center class="montserrat">
                <label class="col-12 mb-0">Please enter your new password</label>
                <label class="col-12 small">(min 8 characters)</label>
                <input type="password" class="form-control" :disabled="sending" v-model="password" placeholder="new password"/>
              </center>
            </div>
            <div class="col-12" v-if="passwordContainsSimbols">
              Your new password can only contain alphanumeric characters!
            </div>
            <div class="col-12" v-if="sent && success">
              <center class="montserrat">
                <label>Password successfully reset</label>
              </center>
            </div>
            <div class="col-12" v-if="sent && !success">
              <center class="montserrat">
                <label>{{message}}</label>
              </center>
            </div>
          </div>
          <div class="modal-body" v-else-if="!ready && message">
            <div class="col-12">
              <center class="montserrat">
                <label class="col-12 mb-0">{{message}}</label>
              </center>
            </div>
          </div>
          <div class="modal-body" v-else-if="!ready && !message">
            <div class="col-12">
              <center class="montserrat">
                <label class="col-12 mb-0">Verifying code...</label>
              </center>
            </div>
          </div>
          <div class="modal-footer" :class="{'p-3': isMobile}" v-if="ready">
            <div class="col-6" :class="{'p-0 m-0 pr-1': isMobile}">
              <button type="button" class="btn button-light-b font4" :class="{'button-mobile': isMobile}" v-if="!sent" :disabled="!passwordOK || sending" @click="send">Sav<span v-if="sending">ing</span><span v-else>e</span></button>
            </div>
            <div class="col-6" :class="{'p-0 m-0 pl-1': isMobile}">
              <button type="button" class="btn button-light-b font4" :class="{'button-mobile': isMobile}" v-if="!sent && !sending" @click="$emit('close')">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    code: {
      type: String,
      required: false,
      default: () => null
    },
    forced: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      password: null,
      ready: false,
      validCode: false,
      message: null,
      sending: false,
      sent: false,
      success: false
    }
  },
  async created () {
    let res
    try {
      res = await this.verifyPasswordResetCode({
        code: this.code,
        forced: this.forced
      })
      this.validCode = res.success
      if (!this.validCode) {
        this.message = res.message
        setTimeout(() => {
          this.$emit('close')
        }, 5000)
      } else {
        this.ready = true
      }
    } catch (error) {
      this.message = error.message
      setTimeout(() => {
        this.$emit('close')
      }, 5000)
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ]),
    passwordOK () {
      const patt = /^[a-z0-9]{8,}$/i
      return patt.test(this.password)
    },
    passwordContainsSimbols () {
      const patt = /^[a-z0-9]+$/i
      return this.password && this.password.length > 0 && !patt.test(this.password)
    }
  },
  methods: {
    ...mapActions([
      'resetPassword',
      'verifyPasswordResetCode'
    ]),
    send () {
      this.sending = true
      this.resetPassword({
        code: this.code,
        forced: this.forced,
        password: this.password
      }).then(res => {
        this.success = res.success
        this.sending = false
        this.sent = true
        this.message = res.message
        setTimeout(() => {
          this.$emit('close')
        }, 5000)
      })
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
/*   padding: 20px 30px; */
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.modal-container-mobile {
  width: 100%;
}

.button-mobile {
  width: 100%;
}

.modal-footer .col-6 {
  text-align: center;
}
</style>
